import { useState } from "react";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useActivities from "./useActivities";

function useWhatsApp() {
  
    const {updateActivityMetadata} = useActivities();


  function formDataToUrlEncoded(formData) {
    const urlSearchParams = new URLSearchParams();
    for (const pair of formData.entries()) {
      urlSearchParams.append(pair[0], pair[1]);
    }
    return urlSearchParams.toString();
  }

  
  async function sendText(taskData) {
    console.log('sendText');
    console.log('taskData', taskData);

    if (!taskData) return;

    try {
      console.log('useWhatsApp -> taskData:', taskData);
      

      const options = {
        method: 'POST',
        headers: {
          apikey: 'cUL1yTaK5rjgau5a5ws16CqKa4LhAl74',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(taskData.text)
      };


      const response = await fetch('https://evoapi.catalystbg.com.br/message/sendText/'+ taskData.wa_instance, options);
      const responseData = await response.json();
      console.log(responseData);

     
      
      if(response.ok){

        toast.success('Sucesso! Tarefa "' + taskData.activity_title + '" enviada.');

        console.log("Sucesso: Atualizando activity_wa_invite_status no servidor...");
        updateActivityMetadata(taskData.activity_id, 'activity_wa_invite_status', 'Enviado' );

      } else {
        
        toast.error('Erro! Tarefa "' + taskData.activity_title + ' (#' + taskData.activity_id +')" teve um problema. Erro: ' + responseData.error);

        console.log("Erro: Atualizando activity_wa_invite_status no servidor...");
        updateActivityMetadata(taskData.activity_id, 'activity_wa_invite_status', 'Erro' );

        // throw new Error('Houve um problema no fetch: (response.ok = false)');
      }
    } catch (error) {
      console.error('Erro no sendText()');
      console.error(error);
      // Se necessário, gerencie o estado de loading aqui

      toast.error('Erro! Tarefa "' + taskData.activity_title + ' (#' + taskData.activity_id +')" teve um problema. Erro: ' + error);

    }
  }

  return { sendText };
}

export default useWhatsApp;