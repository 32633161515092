import { useEffect, useState, useContext } from "react";

import { useAuth } from "./AuthContext";

import { Typeahead } from "react-bootstrap-typeahead";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Skeleton from "react-loading-skeleton";


import useTerms from "./functions/useTerms";
import useGetUsers from "./functions/useGetUsers";

import useWhatsApp from "./functions/useWhatsApp";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {format} from 'date-fns'
import { id, ptBR } from 'date-fns/locale';


import DataTable from 'react-data-table-component';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
  } from '@tanstack/react-query'


  import { CacheBusterContext } from "./App";
import { number } from "echarts";



  
  const queryClient = new QueryClient()
  const formID = '';



  
function isValidDate(dateObject){
    return new Date(dateObject).toString() !== 'Invalid Date';
}



const customStyles = {
	headRow: {
		style: {
			border: 'none',
		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '14px',
		},
	},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};


function ActivitiesTable() {


    const { user, isAdmin } = useAuth();

    const cacheBusterContext = useContext(CacheBusterContext);
    const {cacheBuster, setCacheBuster} = cacheBusterContext || {};

    const {usuarios} = useGetUsers();
    const {empresas, categorias, activityStatus} = useTerms();

    const [optionsClient, setOptionsClient] = useState(['...']);
    const [optionsProfessional, setOptionsProfessional] = useState(['...']);
    const [optionsStatus, setOptionsStatus] = useState(['...']);
    const [optionsCategory, setOptionsCategory] = useState(['...']);
    
    const { sendText } = useWhatsApp();

    const textDefaultBtnEnviar = 'Enviar convite(s)';
    const [textBtnEnviar, setTextBtnEnviar] = useState(textDefaultBtnEnviar);

    const [realStart, setRealStart] = useState(new Date());
    const [realEnd, setRealEnd] = useState(new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)));
    
    const [client, setClient] = useState('');
    const [professional, setProfessional] = useState('');

    const [isEdited, setIsEdited] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);

    // Estado para armazenar status; use row.id ou outra chave única
    const [sendStatuses, setSendStatuses] = useState({});


    
    const conditionalRowStyles = [
        {
          when: row => sendStatuses[row.ID] === "Enviando..." ,
          style: {
            backgroundColor: '#ffeb3b',
            fontWeight: 'bold'
          },
        },
        {
            when: row => sendStatuses[row.ID] === "Enviado",
            style: {
              backgroundColor: '#9fffbf',
              fontWeight: 'bold'
            },
          },
          {
            when: row => sendStatuses[row.ID] === "Aguardando...",
            style: {
              backgroundColor: '#fff6ce',              
            },
          },

          
      ];
    
    
const columns = [
	{
		name: 'Título',
		selector: row => row.post_title,
		sortable: true,
	},
	{
		name: 'Início',
		// selector: row => row.start,
        selector: row => isValidDate(new Date(row.start)) && format(new Date(row.start), 'dd/MM/yyyy HH:mm') ,
        //     🕗
        // { isValidDate(new Date(activity?.activity_registered_check_out_time)) && format(new Date(activity?.activity_registered_check_out_time), 'HH:mm')}
		sortable: true,
	},
    {
		name: 'Cliente',
		selector: row => row.client?.name,
		sortable: true,
	},
    {
		name: 'Status',
		selector: row => row.activity_status?.name,
		sortable: true,
	},
    {
		name: 'Tipo',
		selector: row => row.activity_type?.name,
		sortable: true,
	},
    {
		name: 'Profissional',
		selector: row => row.professional_user_obj?.display_name,
		sortable: true,
	},
    // {
    //     // cell: row => <CustomMaterialMenu size="small" row={row} />,
    //     cell: row => 'Editar',
	// 	allowOverflow: true,
	// 	button: true,
	// 	width: '56px',
    // },
    {
        name: "Convite WhatsApp",
        cell: row => sendStatuses[row.ID] || row?.activity_wa_invite_status || "Pendente",
        ignoreRowClick: true,
        allowOverflow: true,
        button: false,
    },
    
];

    useEffect(() => {

        //se não for admin, definie a var professional com o nome do usuário logado
        if(!isAdmin){
            setProfessional(user?.user_login);
        }

    }, [isAdmin, user]);

    useEffect( () => {
        

        

        const profesionalNames = Array();
        const clientNames = Array();
        const categoryNames = Array();
        const statusNames = Array();

        
        usuarios.map( (user) => {
            profesionalNames.push(user.user_login);      
        });
      
        empresas.map( (client) => {
            //console.log(client);
            if(client.situacao == 'inativa') return;
            clientNames.push(client.name);      
        });
    
        categorias.map( (category) => {
            categoryNames.push(category.name);      
        });
          
        activityStatus.map( (activity_status) => {
            console.log('activity_status.name:', activity_status.name)
            statusNames.push(activity_status.name);      
        });
          
      
        setOptionsProfessional(profesionalNames);
        setOptionsClient(clientNames);
        setOptionsCategory(categoryNames);
        setOptionsStatus(statusNames);
        

    }, [usuarios, empresas, categorias]);



    // const customContextComponent = (selectedCount) => {<div>{selectedCount}</div>}
    // const customContextComponent = (<div>teste</div>);
   
    const CustomContextComponent = ({ selectedCount, onClearSelected }) => (
        <div>
            <span>{selectedCount} item(s) selecionado(s)</span>
            <button onClick={onClearSelected}>Limpar seleção</button>
        </div>
    );


    const subHeaderComponent = (
        
            <div className="container text-start">
                    <div className="row  bg-light p-3">                
                
                
                        <div className="col">          
                            <label className="form-label" htmlFor={formID + 'client'} > Cliente </label>    
                            <Typeahead
                            clearButton="true"
                            options={optionsClient}
                            id={formID + 'client'}
                            emptyLabel="Nenhum item encontrado"
                            inputProps={{name:'client', id:formID + 'client'}}
                            //defaultInputValue="todos"
                            onChange={(val) => setClient(val[0] || '')}
                            
                            />
                        </div>
                        <div className="col">          
                        <label className="form-label" htmlFor={formID + 'professional'} > Profissional </label>    


                            {   isAdmin ?                                     
                                        <Typeahead
                                            clearButton="true"
                                            options={optionsProfessional}
                                            id={formID + 'professional'}
                                            emptyLabel="Nenhum item encontrado"
                                            inputProps={{name:'professional', id:formID + 'professional'}}
                                            //defaultInputValue="Todos"
                                            onChange={(val) => {

                                                setProfessional(val[0] || '');
                                                console.log('val', val[0])
                                                }
                                            }
                                        />      
                                        
                                :
                                        <input type="text"
                                            defaultValue={user?.user_login}
                                            name="activity_status"
                                            id={formID + 'activity_status'} 
                                            className="form-control"
                                            readOnly={true}      
                                        />
                            }
                            

    
                        </div>
                    
                                    
                        <div className="col">          
                            <label className="form-label" htmlFor={formID + 'activity_check_in_time'} > De </label>   
                            <DatePicker
                                selected={realStart}
                                onChange={(date) => setRealStart(date)}
                                //showTimeSelect
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                //name="activity_check_in_time"
                                //id={formID + 'activity_check_in_time'}
                                autoComplete="off"
                                //placeholderText="asfad"
                            />
                        </div>
                        
    
                        <div className="col">          
                            <label className="form-label" htmlFor={formID + 'activity_check_out_time'} > Até </label>   
                            <DatePicker
                                selected={realEnd}
                                onChange={(date) => setRealEnd(date)}
                                //showTimeSelect
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                //name="activity_check_in_time"
                                //id={formID + 'activity_check_in_time'}
                                autoComplete="off"
                                //placeholderText="asfad"
                            />
                        </div>
                
                        <div className="col">          
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id={formID + 'is_edited'}
                                    //onChange={(date) => setIsEdited} 
                                    onChange={(event) => {
    
                                        console.log('val', event.target.checked);                                
                                        setIsEdited(event.target.checked ? 'editado' : '');
                                        
                                        }
                                    }
                                
                                />
                                <label className="form-check-label" htmlFor={formID + 'is_edited'} > Editado </label>   
                            </div>                        
                        </div>
    
                        {/* 
                        <div className="col mb-3">          
                            <button className="btn btn-primary" 
                                data-bs-toggle="offcanvas" 
                                href="#offcanvas"
                                onClick={() => { setOffCanvasContent( <FormActivity updateActivitiesList={updateActivitiesList} putActivity={putActivity} />) } }>
                                Nova atividade </button>
                        </div> */}
    
                        <div className="col">
                            
                            <button
                                className="btn btn-primary"
                                onClick={() => handleDisparo()}
                                disabled={(textBtnEnviar !== textDefaultBtnEnviar)}
                                > {textBtnEnviar} </button>
                        </div>

                    </div>
                        
            </div>
        
    );
    
    const progressComponent = () => {

        return <div>Carregando...</div>;
        return <Skeleton count={5} height={50}  /> 
        
    };


    const { isPending, isFetching, error, data, refetch } = useQuery({
        queryKey: ['activities', realStart, realEnd, client, professional, isEdited, cacheBuster],
        
        queryFn: () =>
            fetch(
                'https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?start=' 
                + new Date(realStart).toISOString() 
                +'&end='+ new Date(realEnd).toISOString() 
                +'&client=' + client 
                + '&professional=' + professional 
                + '&flags=' + isEdited 
                ).then(
            (res) => res.json(),
            ),
    })


    if (error) return 'An error has occurred: ' + error.message

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);

        // You can set state or dispatch with something like Redux so we can use the retrieved data
        console.log('Selected Rows: ', selectedRows);
      };
      

      // Função que verifica se a linha está selecionada
    const isRowSelected = (row) =>
        selectedRows.some((selectedRow) => selectedRow.ID === row.ID);


    const handleRowClicked = (row) => {
        console.log('Row clicked:', row); 

        


        setSelectedRows((prevSelected) => { 

            console.log('prevSelected:', prevSelected);
            // Se já estiver selecionada, remove 
            if (prevSelected.some((r) => r.ID === row.ID)) {
              return prevSelected.filter((r) => r.ID !== row.ID);
            }
            // Senão, adiciona à lista
            return [...prevSelected, row];
          });
    };
    
        
    // const handleDisparo = () =>{

    //     if(selectedRows.length == 0) return alert('Nenhuma linha selecionada');

    //     selectedRows.forEach((row, index) => {
    //         console.log(`Linha selecionada ${index}:`, row);
    //     });
    // }
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleDisparo = async () => {
        
        if(selectedRows.length == 0) return alert('Nenhuma linha selecionada');

        //const reversedSelectedRows = [...selectedRows].reverse();
        const reversedSelectedRows = [...selectedRows];

        reversedSelectedRows.forEach((row, index) => {
            console.log(`Linha selecionada ${index}:`, row);

             // Atualiza status de todos selecionados para "Aguardando..."
             setSendStatuses(prev => ({ ...prev, [row.ID]: "Aguardando..." }));
             
        });

        for (const [index, row] of reversedSelectedRows.entries()) {
            console.log(`Linha selecionada ${index}:`, row);

            // Atualiza status para "Enviando..."
            setSendStatuses(prev => ({ ...prev, [row.ID]: "Enviando..." }));

            setTextBtnEnviar("Enviando...");


            let texto = "Nossa agenda para essa semana, será: \n\n"; 
            texto += "⏰ *Data:* " + format(new Date(row.start), 'dd/MM HH:mm') + "\n";
            texto += "(" + format(new Date(row.start), "eeee, dd 'de' MMMM, 'às' HH'h'mm", { locale: ptBR }) + ") \n\n";
            
            
            
            texto += "📍 *Local:* " + (row.activity_location ? row.activity_location : row.activity_type?.name) + "\n";
            texto += "📝 *Assunto:* " + row.post_title + "\n";
            texto += "👤 *Participante:* " + row.professional_user_obj?.display_name;

            // console.log(texto);
            // return;

            const taskData = {
              text: {
                // number: "120363383116541191@g.us",
                number: row.client_wa_group,
                delay: 4000,
                text: texto,
              },
              wa_instance: row.professional_user_obj?.user_wa_instance,
              activity_title: row.post_title,
              activity_id: row.ID,
            };
        
            // console.log(`Enviando notificação da tarefa: `, row.post_title);
            // await sendText(taskData);

            // console.log(`Aguardando para enviar a próxima notificação...`);
            // await delay(5000);

            
            try {
                console.log(`Enviando notificação da tarefa: `, row.post_title);
                setSendStatuses(prev => ({ ...prev, [row.ID]: "Enviando..." }));
                
                //faz o disparo
                await sendText(taskData);


                // await delay(5000);
                setSendStatuses(prev => ({ ...prev, [row.ID]: "Enviado" }));
                setTextBtnEnviar(textDefaultBtnEnviar);

                

               

              } catch (error) {
                console.error('Erro ao enviar notificação:', error);
                setSendStatuses(prev => ({ ...prev, [row.ID]: "Erro" }));
                setTextBtnEnviar(textDefaultBtnEnviar);
              }
              
              console.log(`Aguardando para enviar a próxima notificação...`);
              await delay(5000);
              console.log('sendStatuses', sendStatuses);

          }

      };

	return (
		
        <div className="container m-4 mx-auto" >

        {/* { subHeaderComponent } */}


        <DataTable
			title="Atividades"
            // key={selectedRows.length}  // ou use um estado renderKey atualizado via useEffect em selectedRows
			columns={columns}
			data={data}
            keyField="ID"
			defaultSortFieldId={2}
			selectableRows={true}
			// selectableRowsComponentProps={true}
			// selectableRowsNoSelectAll={true}
			// selectableRowsHighlight={true}
			// selectableRowsSingle={true}
			// selectableRowsVisibleOnly={true}
			// expandableRows={true}
			// expandOnRowClicked={true}
			// expandOnRowDoubleClicked={true}
			// expandableRowsHideExpander={true}
			pagination={true}
			highlightOnHover={true}
			striped={true}
			// pointerOnHover={true}
			// dense={true}
			// noTableHead={false}
			// persistTableHead={false}
			// progressPending={true}
			// noHeader={false}
			subHeader={true}
			subHeaderComponent={subHeaderComponent}
			// subHeaderAlign='right'
			// subHeaderWrap={true}
			// noContextMenu={true}
            contextMessage={{ singular: 'atividade', plural: 'atividade(s)', message: 'selecionada(s)' }}
            // contextComponent={CustomContextComponent}
            // contextComponent={({ selectedCount, onClearSelected }) => (
            //     <CustomContextComponent
            //         selectedCount={selectedCount}
            //         onClearSelected={onClearSelected}
            //     />
            // )}
            
			fixedHeader={true}
			// fixedHeaderScrollHeight='500px'
			direction='auto'
			responsive={true}
			// disabled={false}

            onSelectedRowsChange={handleChange}
            onRowClicked={handleRowClicked}

            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}


            progressPending={isFetching} 
            progressComponent={'Carregando...'}
            // progressComponent={<progressComponent />}
            // // progressComponent={<Skeleton count={5} height={50} />}
            
		/>

        
        {/* //if (isPending) return 'Loading...'; */}
        {/* {isPending && <Skeleton count={1} height={50}  /> } */}
        {isFetching && <Skeleton count={5} height={50}  /> }





    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition={Bounce}
    />


    </div>
    
	);
};


// export default Disparo;

export default function Disparo() {
    return (
        <QueryClientProvider client={queryClient}>
            <ActivitiesTable />
        </QueryClientProvider>
    )
}
