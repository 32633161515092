import { useEffect, useId, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams, Outlet, useNavigate, useOutletContext } from "react-router-dom";
//import { useGetUsers } from "../functions/useGetUsers";

import Swal from 'sweetalert2'


export default function FormUser() {

    const location = useLocation();
    const navigate = useNavigate();

    

    //console.log(location);
    let urlParams = useParams();

    //const [usuarios, setUsuarios] = useState([]);
    //const user = getUsers(location.state?.id );


    const user = location.state;

    const [userColor, setUserColor] = useState('#f8f8f8');
    //const [user, setUser] = useState(location.state);

    const formRef = useRef(null)

    
    useEffect( () => {
      //console.log(user);
      setUserColor(user?.color);      

      if(formRef.current){
        formRef.current.reset();
      }
      
    }, [location]);



    console.log('user', user);
    console.log('location.state', location.state);
    

    //const [responseMsg, setResponseMsg] = useState("");


    const formID = useId();

    let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
    const headers = {
    Authorization: "Basic " + token,
    //'Content-Type': 'application/json'
    };

    const handleDelete = async (e) =>{
        e.preventDefault();

        
        const user_to_delete = new FormData(e.target);



        Swal.fire({
            title: 'Tem certeza?',
            text: "Este caminho não tem volta.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Deletar ' + user_to_delete.get("username_to_delete"),
          }).then((result) => {
            if (result.isConfirmed) {


                fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/delete_user", {
                    method: "POST",
                    headers: headers,
                    //body: JSON.stringify( user_data ),
                    body: user_to_delete,
                    })
                    .then((response) => {
                        console.log(response);
                        if(response.ok){
                            return response.json().then((data) => {
                                console.log(data);
                                Swal.fire({
                                    title: 'Sucesso',
                                    text: data.message,
                                    icon: 'success',
                                    confirmButtonText: 'Ok'
                                }).then( () => {
                                    //navigate('/usuarios', {replace: true});
                                    navigate('/usuarios');
                                    navigate(0);
                                })
                            })
                        }else{
                            return response.json().then((err) => {
                                return Promise.reject(err);
                            })            
                        }        
                    })      
                    .catch((err) => {
                        console.log("Houve um Erro na atualização.");
                        console.log(err);
                        Swal.fire({
                            title: 'Erro',
                            text: err.message,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    });
            }
          })



    };





  const handleSubmit = async (e) => {
    e.preventDefault();

    //setResponseMsg("");

    // let user_data = {

    //     user_login      : 'adminbg',
    //     user_email      : 'admin@businessgroup.com.br',
    //     user_pass       : 'Fj55t5rhKKg%%M3bQ5jOmf',
    //     first_name      : 'Admin',
    //     last_name       : 'BG',

    // }

    const user_data = new FormData(e.target);

    if (user_data.get("user_pass") !== user_data.get("user_pass2")) {
      //setResponseMsg("As senhas não são iguais.");


      Swal.fire({
        title: 'Erro',
        text: "As senhas não são iguais.",
        icon: 'error',
        confirmButtonText: 'Ok'
    })

      return;
    }

    console.log("Submitting form");
    fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/user", {
      method: "POST",
      headers: headers,
      //body: JSON.stringify( user_data ),
      body: user_data,
    })
      .then((response) => {
        console.log(response);
        if(response.ok){
            return response.json().then((data) => {
                console.log("A solicitação foi feita.");
                console.log(data);
                //setResponseMsg(data.message);
    
                Swal.fire({
                    title: 'Sucesso',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then( () => {
                    //history.push("/home/ongoingActivity");
                    //navigate('/usuarios', {replace: true});
                    navigate('/usuarios');
                    navigate(0);
                })
    
                e.target.reset();                 
            })
        }else{
            return response.json().then((err) => {
                return Promise.reject(err);
            })            
        }        
      })      
      .catch((err) => {
        console.log("Houve um Erro na atualização.");
        console.log(err);

        //setResponseMsg(err.message);

        Swal.fire({
            title: 'Erro',
            text: err.message,
            icon: 'error',
            confirmButtonText: 'Ok'
        })


      });
    console.log("Form submitted.");
  };


  return (
    <>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">

            {/* <h1 className="h2">{urlParams.userName ? urlParams.userName : 'Adicionar usuário'}</h1> */}
            <h1 className="h2">{user?.first_name ? user.first_name + ' ' + user.last_name : 'Adicionar usuário'}</h1>
            
            <div className="btn-toolbar mb-2 mb-md-0">
                <form onSubmit={handleDelete} >
                    <input type="hidden" name="user_id_to_delete" defaultValue={user?.id} />
                    <input type="hidden" name="username_to_delete" defaultValue={user?.user_login} />
                    <button className="btn btn-sm btn-outline-danger"> Deletar </button>
                </form>
            </div>
        </div>


            <div className="card mb-5 bg-body">


              <div className="card-body fs-6 py-15 px-10 text-gray-700">

               

              {/* <Outlet /> */}


                <form 
                  ref={formRef}
                  onSubmit={handleSubmit} >

                <input
                    type="hidden"
                    defaultValue={user?.id}
                    name="user_id"
                />

                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_login"}
                        >
                          Username{" "}
                        </label>
                        <input
                          type="text"
                          defaultValue={user?.user_login}
                          name="user_login"
                          id={formID + "-user_login"}
                          className="form-control"
                          placeholder=""
                          readOnly={user ? true : false}
                          //disabled={user ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_email"}
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="user_email"
                          defaultValue={user?.user_email}
                          id={formID + "-user_email"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-first_name"}
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          defaultValue={user?.first_name}
                          id={formID + "-first_name"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-last_name"}
                        >
                          Sobrenome
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          defaultValue={user?.last_name}
                          id={formID + "-last_name"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_pass"}
                        >
                          Senha
                        </label>
                        <input
                          type="password"
                          name="user_pass"
                          id={formID + "-user_pass"}
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_pass2"}
                        >
                          Confirme a senha
                        </label>
                        <input
                          type="password"
                          name="user_pass2"
                          id={formID + "-user_pass2"}
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_color"}
                        >
                          Cor
                        </label>
                        <input
                          type="color"
                          name="color"
                          id={formID + "-color"}
                          className="form-control"
                          //defaultValue={user?.user_color}
                          value={userColor}
                          onChange={e => setUserColor(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-user_wa_instance"}
                        >
                          Instância do WhatsApp
                        </label>
                        <input
                          type="text"
                          name="user_wa_instance"
                          id={formID + "-user_wa_instance"}
                          className="form-control"
                          defaultValue={user?.user_wa_instance}
                        />

                      </div>
                    </div>
                    
                  </div>



                  <button type="submit" className="btn btn-primary"> Salvar </button>
                </form>

                {/* <div className="response bg-secondary m-10 p-10">
                  {responseMsg}
                </div> */}
              </div>
            </div>
          
        
    </>
  );
}
