import { useEffect, useId, useState } from "react";
import { useLocation, useParams, useSearchParams, Outlet, useNavigate, useOutletContext } from "react-router-dom";
//import { useGetUsers } from "../functions/useGetUsers";

import Swal from 'sweetalert2'


export default function FormTerm() {

  const location = useLocation();
  const navigate = useNavigate();
  
  const outletData = useOutletContext();
  

  let urlParams = useParams();
  const term = location.state;


  const formID = useId();

  let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
  const headers = {
  Authorization: "Basic " + token,
  //'Content-Type': 'application/json'
  };

  const handleDelete = async (e) =>{
      e.preventDefault();

      
      const term_to_delete = new FormData(e.target);



      Swal.fire({
          title: 'Tem certeza?',
          text: "Este caminho não tem volta.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Deletar ' + term_to_delete.get("term_name"),
        }).then((result) => {
          if (result.isConfirmed) {


              fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/delete_term", {
                  method: "POST",
                  headers: headers,
                  //body: JSON.stringify( user_data ),
                  body: term_to_delete,
                  })
                  .then((response) => {
                      console.log(response);
                      if(response.ok){
                          return response.json().then((data) => {
                              console.log(data);
                              Swal.fire({
                                  title: 'Sucesso',
                                  text: data.message,
                                  icon: 'success',
                                  confirmButtonText: 'Ok'
                              }).then( () => {
                                  //navigate('/usuarios', {replace: true});
                                  //navigate('/empresas');
                                  navigate('../');
                                  navigate(0);
                              })
                          })
                      }else{
                          return response.json().then((err) => {
                              return Promise.reject(err);
                          })            
                      }        
                  })      
                  .catch((err) => {
                      console.log("Houve um Erro na atualização.");
                      console.log(err);
                      Swal.fire({
                          title: 'Erro',
                          text: err.message,
                          icon: 'error',
                          confirmButtonText: 'Ok'
                      })
                  });
          }
        })



  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user_data = new FormData(e.target);


    fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/new_term", {
      method: "POST",
      headers: headers,
      //body: JSON.stringify( user_data ),
      body: user_data,
    })
      .then((response) => {
        console.log(response);
        if(response.ok){
            return response.json().then((data) => {
                console.log("A solicitação foi feita.");
                console.log(data);
                //setResponseMsg(data.message);
    
                Swal.fire({
                    title: 'Sucesso',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then( () => {
                    //history.push("/home/ongoingActivity");
                    //navigate('/usuarios', {replace: true});
                    //navigate('/empresas');
                    navigate('./');
                    navigate(0);
                })
    
                e.target.reset();                 
            })
        }else{
            return response.json().then((err) => {
                return Promise.reject(err);
            })            
        }        
      })      
      .catch((err) => {
        console.log("Houve um Erro na atualização.");
        console.log(err);

        //setResponseMsg(err.message);

        Swal.fire({
            title: 'Erro',
            text: err.message,
            icon: 'error',
            confirmButtonText: 'Ok'
        })


      });
    console.log("Form submitted.");
  };


  
  const [formData, setFormData] = useState({});

  useEffect(() => {
    
    setFormData(term || null);

    console.log('term',term);
    console.log('outletData',outletData);
    
    
  }, [term]);


  return (
    <>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">

            {/* <h1 className="h2">{urlParams.userName ? urlParams.userName : 'Adicionar usuário'}</h1> */}
            <h1 className="h2">{term?.name ? term.name : 'Adicionar novo'}</h1>
            
            <div className="btn-toolbar mb-2 mb-md-0">
                <form onSubmit={handleDelete} >
                    <input type="hidden" name="term_id" defaultValue={term?.term_id} />
                    <input type="hidden" name="term_name" defaultValue={term?.name} />
                    <input type="hidden" name="taxonomy_name" defaultValue={term?.taxonomy} />
                    <button className="btn btn-sm btn-outline-danger"> Deletar </button>
                </form>
            </div>
        </div>


            <div className="card mb-5 bg-body">


              <div className="card-body fs-6 py-15 px-10 text-gray-700">

               

              <Outlet />


                <form onSubmit={handleSubmit}>

                <input
                    type="hidden"
                    //defaultValue={term?.term_id}
                    value={formData?.term_id || ''}
                    onChange={e => setFormData({
                      ...formData,
                      term_id: e.target.value
                    })}
                    name="term_id"
                />
                <input
                    type="hidden"
                    defaultValue={term ? term.taxonomy : outletData}
                    name="taxonomy_name"
                />
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-term_name"}
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          //defaultValue={term?.name}
                          value={formData?.name || ''}
                          onChange={e => setFormData({
                            ...formData,
                            name: e.target.value
                          })}
                          name="term_name"
                          id={formID + "-term_name"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      { outletData == 'client' && ( 
                      // {console.log('outletData = ', outletData) }
                      // {console.log('term = location.state = {term}', term) }
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_cidade"}
                        >
                          Cidade
                        </label>
                        <input
                          type="text"
                          value={formData?.cidade || ''}
                          onChange={e => setFormData({
                            ...formData,
                            cidade: e.target.value
                          })}
                          name="client_cidade"
                          id={formID + "-client_cidade"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      )}

                      { outletData == 'client' && ( 
                      // {console.log('outletData = ', outletData) }
                      // {console.log('term = location.state = {term}', term) }
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_wa_group"}
                        >
                          Grupo WhatsApp
                        </label>
                        <input
                          type="text"
                          value={formData?.wa_group || ''}
                          onChange={e => setFormData({
                            ...formData,
                            wa_group: e.target.value
                          })}
                          name="client_wa_group"
                          id={formID + "-client_wa_group"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      )}

                      { outletData == 'client' && ( 
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_situacao"}
                        >
                          Situação
                        </label>
                        

                        <select className="form-select"
                          value={formData?.situacao || ''}
                          onChange={e => setFormData({
                            ...formData,
                            situacao: e.target.value
                          })}
                          name="client_situacao"
                          id={formID + "-client_situacao"}
                          >
                          <option value=""></option>  
                          <option value="ativa">Ativa</option>
                          <option value="inativa">Inativa</option>                          
                        </select>
                        
                      </div>

                      )}

                    </div>
                    
                    
                  </div>

                  

                  <button type="submit" className="btn btn-primary"> Salvar </button>
                </form>

                {/* <div className="response bg-secondary m-10 p-10">
                  {responseMsg}
                </div> */}
              </div>
            </div>
          
        
    </>
  );
}
