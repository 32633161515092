import React from 'react'
import ReactDOM from 'react-dom/client'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App.jsx'
import ErrorPage from './ErrorPage.jsx'
import Activities from './Usuarios.jsx'
import Empresas from './Empresas.jsx'
import Categorias from './Categorias.jsx'
import Usuarios from './Usuarios.jsx'
import Relatorios from './Relatorios.jsx'
import Login from './Login.jsx'
import Disparo from './Disparo.jsx'

import FormUser from './components/FormUser.jsx'
import FormTerm from './components/FormTerm.jsx'

import Atividades from './Atividades.jsx'

import './assets/style.css'
import Agenda from './Agenda.jsx'


import { AuthProvider } from './AuthContext';



/** Analytics and Crash report */

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://aa0fe813a0a2f9e1472d0b499af5b077@o4506005458452480.ingest.sentry.io/4506005460484096",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/catalystbg\.com\.br/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
















const router = createBrowserRouter([
  {
    //basename: '/admin',
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Agenda />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: "agenda",
        element: <Agenda />,
      },
      {
        path: "relatorios",
        element: <Relatorios />,
      },
      {
        path: "usuarios", 
        element: <Usuarios />,
        children: [
          {
            index: true,
            element: <FormUser />,
          },
          {
            path: ":userName",
            element: <FormUser />
          }
        ],
      },
      {
        path: "empresas",
        element: <Empresas />,  
        children: [          
          {
            index: true,
            element: <FormTerm />,
          },
          {
            path: ":termName",
            element: <FormTerm />
          }
        ],      
      },
      {
        path: "categorias",
        element: <Categorias />,  
        children: [          
          {
            index: true,
            element: <FormTerm />,
          },
          {
            path: ":termName",
            element: <FormTerm />
          }
        ],      
      },
      {
        path: "atividades",
        element: <Atividades />,  
        // children: [          
        //   {
        //     index: true,
        //     element: <FormTerm />,
        //   },
        //   {
        //     path: ":termName",
        //     element: <FormTerm />
        //   }
        // ],      
      },
      {
        path: "whatsapp",
        element: <Disparo />,  
        // children: [          
        //   {
        //     index: true,
        //     element: <FormTerm />,
        //   },
        //   {
        //     path: ":termName",
        //     element: <FormTerm />
        //   }
        // ],      
      },
    ],
  },
  {    
    //path: "activities/:contactId",
    path: 'sasdfa/',
    element: <Activities />,
  },
]);



ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>

    {/* <RouterProvider router={router} /> */}

    <AuthProvider>

      {/* <App />  */}
      <RouterProvider router={router} />
    </AuthProvider>


  </React.StrictMode>,

)



// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />}>
//         <Route index element={<Home />} />
//         <Route path="teams" element={<Teams />}>
//           <Route path=":teamId" element={<Team />} />
//           <Route path="new" element={<NewTeamForm />} />
//           <Route index element={<LeagueStandings />} />
//         </Route>
//       </Route>
//       <Route element={<PageLayout />}>
//         <Route path="/privacy" element={<Privacy />} />
//         <Route path="/tos" element={<Tos />} />
//       </Route>
//       <Route path="contact-us" element={<Contact />} />
//     </Routes>
//   </BrowserRouter>
// );