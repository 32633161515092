import React, { useEffect, useId, useState, memo } from "react";
import { Outlet, NavLink } from "react-router-dom";
import useTerms from "./functions/useTerms";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

// Memoize o componente para evitar re-montagem a cada render do componente pai
const ListEmpresas = memo(({ nameFilter, situacaoFilter, setNameFilter, setSituacaoFilter, empresas }) => {
  const filteredEmpresas = empresas.filter(empresa => 
    empresa.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    (situacaoFilter.length === 0 || situacaoFilter.includes(empresa.situacao.toLowerCase()))
  );

  const handleSituacaoChange = (e) => {
    const { value, checked } = e.target;
    setSituacaoFilter(prev => 
      checked ? [...prev, value] : prev.filter(situacao => situacao !== value)
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2 className="h3">Empresas</h2>
      </div>


      <NavLink to='new' className="btn btn-outline-secondary mb-4">    
        <i className="bi-person-plus-fill me-2"></i>
        Adicionar novo
      </NavLink>


      <input 
        type="text" 
        className="form-control mb-3" 
        placeholder="Filtrar empresas por nome" 
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
      />

      <div className="row mb-3">
        <div className="col">
          <div className="form-check">
            <input 
              type="checkbox" 
              className="form-check-input" 
              id="ativa" 
              value="ativa" 
              onChange={handleSituacaoChange}
            />
            <label className="form-check-label" htmlFor="ativa">Ativa</label>
          </div>
        </div>
        <div className="col">
          <div className="form-check ">
            <input 
              type="checkbox" 
              className="form-check-input" 
              id="inativa" 
              value="inativa" 
              onChange={handleSituacaoChange}
            />
            <label className="form-check-label" htmlFor="inativa">Inativa</label>
          </div>
        </div>
      </div>
      
      {empresas.length === 0 && (
        <Skeleton 
          count={5.8} 
          height={30} 
          baseColor={'var(--bs-secondary-bg-subtle)'} 
          highlightColor={'var(--bs-tertiary-bg)'} 
        />
      )}

      <ul className="nav nav-pills mb-auto mw-100 overflow-y-auto">
        {filteredEmpresas.map((empresa) => (
          <li key={empresa.term_id} className="w-100">
            <NavLink to={empresa.slug} state={empresa} className="nav-link text-body-secondary">
              {empresa.name}  
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
});

export default function Empresas() {
  const [response, setResponse] = useState("");
  const formID = useId();
  
  const { empresas } = useTerms();
  const [nameFilter, setNameFilter] = useState("");
  const [situacaoFilter, setSituacaoFilter] = useState([]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3 bg-body-secondary d-flex flex-column vh-100 ">
            <ListEmpresas 
              nameFilter={nameFilter} 
              situacaoFilter={situacaoFilter} 
              setNameFilter={setNameFilter} 
              setSituacaoFilter={setSituacaoFilter} 
              empresas={empresas} 
            />
          </div>

          <div className="col-9">
            <Outlet context={'client'} />
          </div>
        </div>
      </div>
    </>
  );
}