import { useEffect, useId, useState, useContext } from "react";
import { useLocation, useParams, useSearchParams, Outlet, useNavigate, useOutletContext } from "react-router-dom";
//import { useGetUsers } from "../functions/useGetUsers";

import { useAuth } from "../AuthContext";

import useGetUsers from "../functions/useGetUsers";

import { Typeahead } from "react-bootstrap-typeahead";
// import 'react-bootstrap-typeahead/css/Typeahead.css'
// import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('br', ptBR)
setDefaultLocale('br');

import Swal from 'sweetalert2'
import TextFieldWithLabel from "./TextFieldWithLabel";
import useTerms from "../functions/useTerms";
import useActivities from "../functions/useActivities";


import { CacheBusterContext } from "../App";


export default function FormActivity({updateActivitiesList, putActivity, activityData, startTime, endTime}) {

  const { isAdmin } = useAuth();
  
  const {cacheBuster, setCacheBuster} = useContext(CacheBusterContext);
  
  const {usuarios} = useGetUsers();
  const {empresas, categorias, activityStatus} = useTerms();
  const {isLoading} = useActivities();
  

  const formID = useId();

  const [category, setCategory] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState(['...']);

  const [client, setClient] = useState([]);
  const [optionsClient, setOptionsClient] = useState(['...']);

  const [professional, setProfessional] = useState([]);
  const [optionsProfessional, setOptionsProfessional] = useState(['...']);
  
  const [optionsStatus, setOptionsStatus] = useState(['...']);
  


  const [optionsActivityType, setOptionsActivityType] = useState(['Interna', 'Externa', 'Online']);

  const [checkinDate, setCheckinDate] = useState();
  const [checkoutDate, setCheckoutDate] = useState();
  const [registeredCheckinDate, setRegisteredCheckinDate] = useState();
  const [registeredCheckoutDate, setRegisteredCheckoutDate] = useState();
  

  function convertToISO8601(dateString) {
    const parts = !!dateString ? dateString.split(' ') : '2023/01/01 00:00:00'.split(' ');
    
    const datePart = parts[0];
    const timePart = parts[1];
  
    const [day, month, year] = datePart.split('/');
    const [hour, minute] = timePart.split(':');
  
    //const isoString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00.000Z`;
    const isoString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  
    return isoString;
  }


  function handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);



    data.get('activity_check_in_time') && data.set('activity_check_in_time', convertToISO8601(data.get('activity_check_in_time')) );
    data.get('activity_check_in_time') && data.set('activity_check_out_time', data.get('activity_check_in_time').slice(0,11) + data.get('activity_check_out_time'));

    data.get('activity_registered_check_in_time') && data.set('activity_registered_check_in_time', convertToISO8601(data.get('activity_registered_check_in_time')) );
    data.get('activity_registered_check_out_time') && data.set('activity_registered_check_out_time', convertToISO8601(data.get('activity_registered_check_out_time')) );

    // console.log(Object.fromEntries(data) );
    
    // console.log(data.get('activity_check_in_time'));
    // console.log(data.get('activity_check_out_time'));
    // return;

    //Verifica se não está editando para uma data antiga
    if( (new Date(data.get('activity_check_in_time')) < new Date() )  && !isAdmin ) { //se a data é no passado
      //mostra modal avisando que somente admins podem editar
      Swal.fire({
          icon: "error",
          title: "Data início no passado!",
          text: "Esta função é exclusiva para administradores",
          footer: 'Data Entrada: ' + data.get('activity_check_in_time')
        });
      return;
    }


    //e.target.reset();
    (async () => {
      await putActivity(data);

      setCacheBuster( new Date().valueOf() );

      bootstrap.Offcanvas.getInstance('#offcanvas').hide();
      
      //updateActivitiesList();
      //e.target.reset();
    })()


  }


  function isValidDate(dateObject){
    return new Date(dateObject).toString() !== 'Invalid Date';
  }

  useEffect( () => {
   
    console.log('use effect usuarios', usuarios);
    console.log('startTime', startTime);
    console.log('startTime Date', new Date( startTime ));
    

    const profesionalNames = Array();
    const clientNames = Array();
    const categoryNames = Array();
    const statusNames = Array();
    
    

    usuarios.map( (user) => {
      profesionalNames.push(user.user_login);      
    });

    empresas.map( (client) => {
      //console.log(client);
      if(client.situacao == 'inativa') return;
      clientNames.push(client.name);      
    });

    categorias.map( (category) => {
      categoryNames.push(category.name);      
    });
    
    activityStatus.map( (activity_status) => {
      console.log('activity_status.name:', activity_status.name)
      statusNames.push(activity_status.name);      
    });
    

    setOptionsProfessional(profesionalNames);
    setOptionsClient(clientNames);
    setOptionsCategory(categoryNames);
    setOptionsStatus(statusNames);

    let _indate = new Date( activityData?.activity_check_in_time );
    let _outdate = new Date( activityData?.activity_check_out_time );
    let _registered_indate = new Date( activityData?.activity_registered_check_in_time );
    let _registered_outdate = new Date( activityData?.activity_registered_check_out_time );
    
    activityData && isValidDate(_indate) && setCheckinDate( _indate );
    activityData && isValidDate(_outdate) && setCheckoutDate( _outdate );
    activityData && isValidDate(_registered_indate) && setRegisteredCheckinDate( _registered_indate );
    activityData && isValidDate(_registered_outdate) && setRegisteredCheckoutDate( _registered_outdate );

    //vindo pelo calendario
    startTime && isValidDate(new Date( startTime )) ? setCheckinDate( new Date( startTime )) : '';
    endTime  && isValidDate(new Date( endTime )) ? setCheckoutDate( new Date( endTime )) : '';

    return () => {
      setCheckinDate('');
  	};

  }, [usuarios, empresas, categorias, activityData, startTime]);




  return (
    <>


  


    <form onSubmit={handleSubmit}>

      
      <input type="hidden" name="ID" value={ activityData ? activityData.ID : "new" } />
      <input type="hidden" name="flags" value="EDITADO" />


      <div className="row">
        <div className="col mb-3">          
            {/* <TextFieldWithLabel formID={formID} label="Título" name="post_title" /> */}

            <label className="form-label" htmlFor={formID + 'post_title'} > Título </label>
            <input type="text"
            //defaultValue={user?.user_login}
            defaultValue={activityData?.post_title}
            name="post_title"
            id={formID + 'post_title'}
            className="form-control"
            placeholder=""
            autoComplete="off"
            />
        </div>
      </div>

      <div className="row">
        <div className="col mb-3">          
            <label className="form-label" htmlFor={formID + 'client'} > Cliente </label>    
            <Typeahead
              clearButton="true"
              options={optionsClient}
              id={formID + 'client'}
              emptyLabel="Nenhum item encontrado"
              inputProps={{name:'client', id:formID + 'client'}}
              defaultInputValue={activityData?.client?.name}
              
            />
        </div>
        <div className="col mb-3">          
          <label className="form-label" htmlFor={formID + 'professional'} > Profissional </label>    
          <Typeahead
            clearButton="true"
            options={optionsProfessional}
            id={formID + 'professional'}
            emptyLabel="Nenhum item encontrado"
            inputProps={{name:'professional', id:formID + 'professional'}}
            defaultInputValue={activityData?.professional_id?.name}
          />          
        </div>
      </div>

      <div className="row">
        <div className="col mb-3">          
          <label className="form-label" htmlFor={formID + 'activity_check_in_time'} > Entrada </label>   
        <DatePicker
            selected={checkinDate}
            onChange={(date) => setCheckinDate(date)}
            showTimeSelect
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            timeCaption="Hora"
            className="form-control"
            name="activity_check_in_time"
            id={formID + 'activity_check_in_time'}
            autoComplete="off"
            //placeholderText="asfad"
          />

        </div>

        <div className="col mb-3">
          <label className="form-label" htmlFor={formID + 'activity_check_out_time'} > Saída </label> 
            <DatePicker
              selected={checkoutDate}
              onChange={(date) => setCheckoutDate(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Hora"
              dateFormat="HH:mm"
              //dateFormat="dd/MM/yyyy HH:mm"
              className="form-control"
              name="activity_check_out_time"
              id={formID + 'activity_check_out_time'}   
              autoComplete="off"
            />          
        </div>
        
        
      </div>



      <div className="row">
      
        <div className="col mb-3">          
          <label className="form-label" htmlFor={formID + 'activity_registered_check_in_time'} > Entrada realizada</label>   
          <DatePicker
              selected={registeredCheckinDate}
              onChange={(date) => setRegisteredCheckinDate(date)}
              showTimeSelect
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              timeCaption="Hora"
              className="form-control"
              name="activity_registered_check_in_time"
              id={formID + 'activity_registered_check_in_time'}
              autoComplete="off"
              readOnly={!isAdmin}
              //placeholderText="asfad"
            />
        </div>

        <div className="col mb-3">          
          <label className="form-label" htmlFor={formID + 'activity_registered_check_out_time'} > Saída realizada</label> 
            <DatePicker
              selected={registeredCheckoutDate}
              onChange={(date) => setRegisteredCheckoutDate(date)}
              showTimeSelect
              timeIntervals={15}
              timeCaption="Hora"
              dateFormat="dd/MM/yyyy HH:mm"
              className="form-control"
              name="activity_registered_check_out_time"
              id={formID + 'activity_registered_check_out_time'}   
              autoComplete="off"
              readOnly={!isAdmin}
            />
        </div>

      </div>



      <div className="row">
        <div className="col mb-3">
            <label className="form-label" htmlFor={formID + 'activity_type'} > Tipo </label>    
            <Typeahead
              // onChange={(selected) => {
              //   setActivityType(selected);
              // }}
              //labelKey="name"
              //multiple
              // selected={activityType}
              clearButton="true"
              options={optionsActivityType}
              id={formID + 'activity_type'}
              emptyLabel="Nenhum item encontrado"
              inputProps={{name:'activity_type', id:formID + 'activity_type'}}
              defaultInputValue={activityData?.activity_type?.name}
            />
        </div>
        <div className="col mb-3">          
          
            
            <label className="form-label" htmlFor={formID + 'activity_category'} > Categoria </label>
            {/* o seguinte seria para multipla seleção, o que estava dando pau para popular o campo na edição
              <input type="hidden" name="activity_category" value={category} />
              <Typeahead
              onChange={(selected) => {
                console.log(selected);
                setCategory(selected);
              }}
              selected={category}
              options={optionsCategory}
              multiple
              //allowNew // TODO: validar pois ele manda um objeto
              //newSelectionPrefix="Adicionar item: "
              emptyLabel="Nenhum item encontrado"
              id="activity_category"
              inputProps={{id:formID + 'activity_category'}}
              defaultInputValue={activityData?.activity_category?.name}
            /> */}

            <Typeahead
              clearButton="true"
              options={optionsCategory}
              emptyLabel="Nenhum item encontrado"
              id="activity_category"
              inputProps={{name:'activity_category', id:formID + 'activity_category'}}
              defaultInputValue={activityData?.activity_category?.name}
              
            />
        </div>

        



      </div>

      
      <div className="row">
        <div className="col mb-3">          

            <label className="form-label" htmlFor={formID + 'activity_location'} > Local da reunião</label>
            <input type="text"
            //defaultValue={user?.user_login}
            defaultValue={activityData?.activity_location}
            name="activity_location"
            id={formID + 'activity_location'}
            className="form-control"
            placeholder=""
            autoComplete="off"
            />
        </div>


        <div className="col mb-3">          
          
            
            <label className="form-label" htmlFor={formID + 'activity_status'} > Status </label>

            {
            isAdmin ? 
                <Typeahead
                clearButton="true"
                //highlightOnlyResult="true"
                options={optionsStatus}
                emptyLabel="Nenhum item encontrado"
                id="activity_category"
                inputProps={{name:'activity_status', id:formID + 'activity_status'}}
                defaultInputValue={activityData?.activity_status?.name || "Aguardando"}
                

                />
            :
              <input type="text"
                defaultValue={activityData?.activity_status?.name || "Aguardando"}
                name="activity_status"
                id={formID + 'activity_status'} 
                className="form-control"
                readOnly={true}      
              />
            }
            

            

            {/* <select name="activity_status"
              defaultValue={activityData?.activity_status?.name}
              //value={activityData?.activity_status?.name}
              className="form-select"
              >
              {optionsStatus.map( (activity_status) => {
                  return <option key={activity_status} value={activity_status}>{activity_status}</option>
              })}
            </select> */}

        </div>

        
      </div>
      
      

      <div className="row">
          <div className="col mb-3">          
              <label className="form-label" htmlFor={formID + 'activity_obs'} > OBS </label>
              <textarea
              //defaultValue={user?.user_login}
              defaultValue={activityData?.activity_obs}
              name="activity_obs"
              id={formID + 'activity_obs'}
              className="form-control"
              placeholder=""
              autoComplete="off"
              />
          </div>
        </div>
{/*         
        <div className="row">
          <div className="col mb-3">          
              <label className="form-label" htmlFor={formID + 'activity_checkin_location'} > GPS Início </label>
              <input type="text"
              //defaultValue={user?.user_login}
              defaultValue={activityData?.activity_checkin_location}
              name="activity_checkin_location"
              id={formID + 'activity_checkin_location'}
              className="form-control"
              placeholder=""
              autoComplete="off"
              />
          </div>
          <div className="col mb-3">          
              <label className="form-label" htmlFor={formID + 'activity_checkout_location'} > GPS Fim </label>
              <input type="text"
              //defaultValue={user?.user_login}
              defaultValue={activityData?.activity_checkout_location}
              name="activity_checkout_location"
              id={formID + 'activity_checkout_location'}
              className="form-control"
              placeholder=""
              autoComplete="off"
              />
          </div>
        </div> */}
        


      <button type="submit" className="btn btn-primary" disabled={isLoading}> Enviar </button>
    </form>

 
  
  
  


    </>
  );
}
