import { useState, useEffect } from "react";

import Swal from "sweetalert2";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

//TODO: Tirar Swal daqui
function useActivities() {
  
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  
  const [forceReloadCalendar,setForceReloadCalendar] = useState( new Date().valueOf() );



  let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
  const headers = { Authorization: "Basic " + token};


  async function getActivities() {

    setIsLoading(true);

    try {
      
        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity", {
          method: "GET",
          headers: headers, 
        })

        if(response.ok){
          let data = await response.json();
          console.log('A solicitação ACTIVITIES foi feita.');
          console.log(data);      
          setActivities(data);
          setIsLoading(false);

        }else{
          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no getActivities()');
      console.log(error);
      setIsLoading(false);

    }

  }


  function formDataToUrlEncoded(formData) {
    const urlSearchParams = new URLSearchParams();
  
    for (const pair of formData.entries()) {
      urlSearchParams.append(pair[0], pair[1]);
    }
  
    return urlSearchParams.toString();
  }




  async function putActivity(activitydata) {

    


    console.log('activitydata', activitydata);
    //tem que ser 'Content-Type','application/x-www-form-urlencoded'


    setIsLoading(true);

    if(!activitydata) return;

    //console.log(Object.fromEntries(activitydata) );

    //return;

    try {

        console.log('activitydata');
        console.log(Object.fromEntries(activitydata) );
        //console.log(JSON.stringify( Object.fromEntries(activitydata) ));

        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity", {
          method: "PUT",
          headers: { 
            'Authorization': "Basic " + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          
          //body: JSON.stringify( Object.fromEntries(activitydata) ),
          body: formDataToUrlEncoded(activitydata),
          
        })

        let responseData = await response.json();
        console.log('A solicitação putActivity foi feita.');
        
        if(response.ok){
          console.log('response ok');
          console.log(responseData);
          
          setForceReloadCalendar( new Date().valueOf() );

          Swal.fire({
            title: 'Sucesso',
            text: responseData.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then( () => {
            //updateActivitiesList();            
            //setIsLoading(true);
          })
        }else{
          console.log('response NOT ok');
          console.log(responseData);

          Swal.fire({
            title: 'Erro',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          // .then( () => {
          //     navigate('../');
          //     navigate(0);
          // })

          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no PostActivity()');
      
      console.log(error);
      setIsLoading(false);

    }

  }




  async function activityChangeTime(activitydata) {


    setIsLoading(true);

    if(!activitydata) return;

    try {

        console.log('activitydata');
        //console.log(Object.fromEntries(activitydata) );
        //console.log(JSON.stringify( Object.fromEntries(activitydata) ));

        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity_change_time", {
          method: "PUT",
          headers: { 
            'Authorization': "Basic " + token,
            'Content-Type': 'application/json'
          },
          
          //body: JSON.stringify( Object.fromEntries(activitydata) ),
          body: JSON.stringify(activitydata),
          
        })

        let responseData = await response.json();
        console.log('A solicitação putActivity foi feita.');
        
        if(response.ok){
          console.log('response ok');
          console.log(responseData);

          toast.success(responseData.message);

          // Swal.fire({
          //   title: 'Sucesso',
          //   text: responseData.message,
          //   icon: 'success',
          //   confirmButtonText: 'Ok'
          // }).then( () => {
          //   // updateActivitiesList();
          //   // setIsLoading(true);
          // })
        }else{
          console.log('response NOT ok');
          console.log(responseData);

          Swal.fire({
            title: 'Erro',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })

          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no activityChangeTime()');
      
      console.log(error);
      setIsLoading(false);

    }

  }


  // useEffect(() => {

  //   getActivities();
    
  // }, []);



  const updateActivitiesList = async () => {
    console.log('updating activities...');
    await getActivities();
  }


  
  async function deleteActivity(activity_id) {


    if(!activity_id) return;

    try {

      setIsLoading(true);

        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?activity_id=" + activity_id, {
          method: "DELETE",
          headers: { 
            'Authorization': "Basic " + token,
            //'Content-Type': 'application/x-www-form-urlencoded'
          },
          
          //body: JSON.stringify( Object.fromEntries(activitydata) ),
          //body: formDataToUrlEncoded(activitydata),
          
        })

        let responseData = await response.json();
        console.log('A solicitação deleteActivity foi feita.');
        
        if(response.ok){
          console.log('response ok');
          console.log(responseData);

          setForceReloadCalendar( new Date().valueOf() );
          console.log('forceReloadCalendar', forceReloadCalendar);
          console.log('useActivities.js > deleteActivity > response.ok > forceReloadCalendar', forceReloadCalendar);

          
          Swal.fire({
            title: 'Sucesso',
            text: responseData.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then( () => {
            //updateActivitiesList();
            bootstrap.Offcanvas.getInstance('#offcanvas').hide();
            setIsLoading(false);
          })
        }else{
          console.log('response NOT ok');
          console.log(responseData);

          Swal.fire({
            title: 'Erro',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          // .then( () => {
          //   // bootstrap.Offcanvas.getInstance('#offcanvas').hide();
          // })

          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no deleteActivity()');
      
      console.log(error);
      setIsLoading(false);

    }

  }









  async function updateActivityMetadata(activity_id, meta_key, meta_value) {


    setIsLoading(true);

    if(!activity_id || !meta_key || !meta_value) return;

    const data = {};    
    data.ID = activity_id;
    data.meta_key = meta_key;
    data.meta_value = meta_value;

    // const data = new FormData();
    // data.append('activity_id', activity_id);
    // data.append('meta_key', meta_key);
    // data.append('meta_value', meta_value);
    

    try {

        console.log('activitydata');
        console.log('data(activity_wa_invite_status)', data);
        
        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity", {
          method: "PUT",
          headers: { 
            'Authorization': "Basic " + token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
            'Content-Type': 'application/json'
          },
          //body: formDataToUrlEncoded(data),
          body: JSON.stringify(data),


        })

        let responseData = await response.json();
        console.log('A solicitação updateActivityMetadata foi feita.');
        
        if(response.ok){
          console.log('(updateActivityMetadata) response ok');
          console.log(responseData);

          toast.success(responseData.message);

        }else{
          console.log('(updateActivityMetadata) response NOT ok');
          console.log(responseData);

          toast.error(responseData.message);

          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no updateActivityMetadata()');
      
      console.log(error);
      setIsLoading(false);

    }

  }























  return {activities, updateActivitiesList, putActivity, isLoading, deleteActivity, activityChangeTime, forceReloadCalendar, updateActivityMetadata};

}


export default useActivities;